// extracted by mini-css-extract-plugin
export var buttons = "product-module--buttons--SIeYJ";
export var description = "product-module--description--fZorK";
export var desktopButtons = "product-module--desktopButtons--VsWVK";
export var details = "product-module--details--6GUjh";
export var firstImageContainer = "product-module--firstImageContainer--+ahMY";
export var firstImageNarrowContainer = "product-module--firstImageNarrowContainer--BjJl6";
export var image = "product-module--image--+XQQa";
export var images = "product-module--images--t37IY";
export var mobileButtons = "product-module--mobileButtons--z+x+9";
export var mobileHeaderImage = "product-module--mobileHeaderImage--5qQL8";
export var mobileHeaderImageContainer = "product-module--mobileHeaderImageContainer--ZI0Qs";
export var product = "product-module--product--X3h65";
export var productInfo = "product-module--productInfo--Zhs4g";
export var title = "product-module--title--BEWIf";